import React, { useState } from "react";
import { Routes, Route, Link } from "react-router-dom";
import {
  CssBaseline,
  Drawer,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import Appbar from "./components/Appbar";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import theme from "./theme";
import AccessoriesPage from "./pages/AccessoriesPage";
import LensPage from "./pages/LensPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUserRole } from "./utils/roleUtils";
import AdminRoute from "./components/AdminRoute";
import GlassesPage from "./pages/GlassesPage";
import AdminGlassesPage from "./pages/AdminGlassesPage";
import FeedbackPage from "./pages/FeedbackPage";
import AdminAccessoriesPage from "./pages/AdminAccessoriesPage";
import GradientDivider from "./components/GradientDivider";
import GlassDetailsPage from "./pages/GlassDetailsPage";
import ContactPage from "./pages/ContactPage";

const drawerWidth = 240;
const appBarHeight = 120; // Adjust this based on your AppBar height

const App: React.FC = () => {
  const roles = getUserRole();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Determine if the screen is mobile size
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuItemClick = () => {
    if (isMobile) {
      setMobileOpen(false);
    }
  };

  const drawerContentItems = ["Home", "Rame Femei", "Rame Barbati", "Accesorii", "Feedback", "Contact"];

  const drawerContent = (
    <List sx={{paddingLeft: "14px"}}>
      {drawerContentItems
        .concat(roles && roles.includes("ROLE_ADMIN") ? ["Editeaza Rame"] : [])
        .concat(
          roles && roles.includes("ROLE_ADMIN") ? ["Editeaza Accesorii"] : []
        )
        .map((text, index) => (
          <Box>
            <ListItem key={text} disablePadding>
              <ListItemButton
                component={Link}
                to={
                  text === "Home"
                    ? "/"
                    : `/${text.toLowerCase().replace(/\s+/g, "")}`
                }
                onClick={handleMenuItemClick} // Close the drawer after clicking on a menu item
                disableGutters
              >
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
            { index < drawerContentItems.length - 1 && <GradientDivider />}
          </Box>
        ))}
    </List>
  );

  const drawer = (
    <Drawer
      variant={isMobile ? "temporary" : "permanent"}
      open={isMobile ? mobileOpen : true}
      onClose={handleDrawerToggle}
      sx={{
        width: isMobile ? "100%" : drawerWidth, // Full width on mobile, fixed width on desktop
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: isMobile ? "100%" : drawerWidth,
          top: `${appBarHeight}px`,
          boxSizing: "border-box",
        },
      }}
    >
      {drawerContent}
    </Drawer>
  );

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <CssBaseline />
        <Box sx={{ display: "flex" }}>
          <Appbar onMenuClick={handleDrawerToggle} />
          {drawer}
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: "background.default",
              p: 3,
              ml: isMobile ? 0 : `${drawerWidth}px`, // Ensure the main content starts after the Drawer
              mt: `${appBarHeight}px`, // Offset content below the AppBar
              maxWidth: "1200px", // Maximum width for centering
              mx: "auto", // Center content horizontally
            }}
          >
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/feedback" element={<FeedbackPage />} />
              <Route
                path="/ramefemei"
                element={<GlassesPage gender="female" />}
              />
              <Route
                path="/ramebarbati"
                element={<GlassesPage gender="male" />}
              />
              <Route path="/rame/:id" element={<GlassDetailsPage />} />
              <Route path="/accesorii" element={<AccessoriesPage />} />
              <Route path="/lentile" element={<LensPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/" element={<HomePage />} />

              <Route element={<ProtectedRoute />}>
                <Route
                  path="editeazarame"
                  element={
                    <AdminRoute>
                      <AdminGlassesPage />
                    </AdminRoute>
                  }
                />

                <Route
                  path="editeazaaccesorii"
                  element={
                    <AdminRoute>
                      <AdminAccessoriesPage />
                    </AdminRoute>
                  }
                />
              </Route>

              <Route path="*" element={<div>Not Found</div>} />
            </Routes>
          </Box>
        </Box>
        <ToastContainer />
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
