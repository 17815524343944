import axios from 'axios';


const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'http://localhost:8080',
});


const getAuthToken = () => localStorage.getItem('authToken');


axiosInstance.interceptors.request.use(
    config => {
        const token = getAuthToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`; 
        }
        return config;
    },
    error => Promise.reject(error)
);

export default axiosInstance;
