import React from "react";
import {
  AppBar,
  Toolbar,
  Button,
  useTheme,
  Box,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu"; // Import the Menu icon
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
interface AppbarProps {
  onMenuClick: () => void; // Add this prop to handle menu button clicks
}

const Appbar: React.FC<AppbarProps> = ({ onMenuClick }) => {
  const { logout } = useAuth();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Determine if the screen is mobile size

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const handleLogoClick = () => {
    navigate("/"); // Adjust the path as needed
  };

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        backgroundColor: "white",
        color: theme.palette.getContrastText(theme.palette.primary.main),
        backdropFilter: "none", // Ensure no background filter is applied
        boxShadow: "none", // Removes the shadow
        height: "120px", // Increase the height of the AppBar
        zIndex: (theme) => theme.zIndex.drawer + 1, // Ensure AppBar is above other elements
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          height: "100%", // Full height of the AppBar
        }}
      >
        {/* Menu button for opening the drawer */}
        {isMobile && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={onMenuClick}
            sx={{
              position: "absolute", // Positioning the menu button to the left
              left: 16,
              color: "black", // Set the MenuIcon color to black
              fontSize: "30px",
              zIndex: 1300, // Adjust the icon size if needed
            }}
          >
            <MenuIcon />
          </IconButton>
        )}

        {/* Box to center the logo */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexGrow: 1,
            position: "relative",
          }}
        >
          <IconButton onClick={handleLogoClick} sx={{ padding: 0 }}>
            <img
              src="4okelari_logo.png" 
              alt="Logo"
              style={{ height: "110px" }}
            />
          </IconButton>
        </Box>

        {isAuthenticated && (
          <Button
            startIcon={<ExitToAppIcon />}
            onClick={handleLogout}
            sx={{
              color: theme.palette.common.white,
              typography: "button", // Ensures the font is consistent with other button texts
              "&:hover": {
                backgroundColor: theme.palette.background.default,
                color: theme.palette.secondary.dark,
              },
              position: "absolute", // Positioning the logout button to the right
              right: 16,
              fontSize: "20px", // Adjust the button font size if needed
            }}
          >
            Logout
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Appbar;
