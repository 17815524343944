import React from 'react';
import { Navigate } from 'react-router-dom';
import { getUserRole } from '../utils/roleUtils';

interface AdminRouteProps {
  children: JSX.Element;
}

const AdminRoute: React.FC<AdminRouteProps> = ({ children }) => {
  const roles = getUserRole();
  return roles && roles.includes('ROLE_ADMIN') ? children : <Navigate to="/" />;
};

export default AdminRoute;
