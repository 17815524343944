import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { getUserRole } from '../utils/roleUtils';

interface AdminActionsProps {
  onAdd: () => void;  
}

const AdminActions: React.FC<AdminActionsProps> = ({ onAdd }) => {
  const roles = getUserRole();

  if (!roles || !roles.includes('ROLE_ADMIN')) {
    return null;
  }

  return (
    <div>
      <Tooltip title="Add New">
        <IconButton onClick={onAdd} color="primary">
          <AddCircleIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default AdminActions;
