import { Box, Button } from "@mui/material";
import React from "react";
import PhoneIcon from "@mui/icons-material/Phone";

interface CallButtonProps {
  isHomePage?: boolean;
}

const CallButton: React.FC<CallButtonProps> = ({ isHomePage = false }) => {
  const phoneNumber = "0770-105-376";

  const handleCallNow = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Button
        variant="contained"
        startIcon={<PhoneIcon />}
        onClick={handleCallNow}
        sx={{
          borderRadius: isHomePage ? "20px" : "4px", // Rounded corners for homepage, rectangle for other pages
          backgroundColor: "#111810",
          color: "#fff",
          width: "100%",
          "&:hover": { backgroundColor: "#333940" },
          px: isHomePage ? undefined : 4, // Increase padding horizontally for rectangle button
          py: isHomePage ? undefined : 2, // Increase padding vertically for rectangle button
        }}
      >
        Sună acum
      </Button>
    </Box>
  );
};

export default CallButton;
