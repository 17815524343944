import React, { useEffect, useState} from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Container,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  CardMedia,
  IconButton,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import axiosInstance from "../utils/axiosConfig";
import { toast } from "react-toastify";

interface Accessory {
  id: number;
  name: string;
  description: string;
  price: string;
  imageData: string; // Base64-encoded image data
}

const AdminAccessoriesPage: React.FC = () => {
  const [accessories, setAccessories] = useState<Accessory[]>([]);
  const [editAccessory, setEditAccessory] = useState<Accessory | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);

  const fetchData = () => {
    axiosInstance
      .get<Accessory[]>("/accessories/public")
      .then((response) =>
        setAccessories(
          response.data.map((accessory) => ({
            ...accessory,
            imageData: accessory.imageData
              ? `data:image/jpeg;base64,${accessory.imageData}`
              : "/static/images/default.png",
          }))
        )
      )
      .catch((error) => toast.error("Failed to fetch accessories"));
  };

  const handleEdit = (accessory: Accessory) => {
    setEditAccessory(accessory);
    setDialogOpen(true);
  };

  const handleDelete = (id: number) => {
    axiosInstance
      .delete(`/accessories/${id}`)
      .then(() => {
        fetchData();
        toast.success("Accessory deleted successfully");
      })
      .catch((error) => toast.error(error.response.data.message));
  };

  const handleClose = () => {
    setDialogOpen(false);
    setEditAccessory(null);
    setSelectedImage(null);
  };

  const handleSave = () => {
    if (editAccessory) {
      const formData = new FormData();
      formData.append(
        "accessory",
        new Blob(
          [
            JSON.stringify({
              name: editAccessory.name,
              description: editAccessory.description,
              price: editAccessory.price,
            }),
          ],
          {
            type: "application/json",
          }
        )
      );

      if (selectedImage) {
        formData.append("image", selectedImage);
      }

      if (editAccessory.id) {
        axiosInstance
          .put(`/accessories/${editAccessory.id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            fetchData();
            handleClose();
            toast.success("Accessory saved successfully");
          })
          .catch((error) => toast.error(error.response.data.message));
      } else {
        axiosInstance
          .post(`/accessories`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            fetchData();
            handleClose();
            toast.success("Accessory added successfully");
          })
          .catch((error) => toast.error(error.response.data.message));
      }
    } else {
      toast.error("No accessory data available to save.");
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditAccessory((prev) => ({ ...prev!, [name]: value }));
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(event.target.files[0]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container>
      <Typography variant="h4" sx={{ mt: 4, mb: 2 }}>
        Editează Accesorii
      </Typography>
      <Grid container spacing={4}>
        {accessories.map((accessory) => (
          <Grid item key={accessory.id} xs={12} sm={6} md={4}>
            <Card>
              <CardMedia
                component="img"
                sx={{
                  height: { xs: 140, sm: 300 },
                  width: "100%",
                  objectFit: "cover",
                }}
                image={accessory.imageData}
                alt={accessory.name}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {accessory.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {accessory.description}
                </Typography>
                <Typography variant="h6">
                  {accessory.price} lei
                </Typography>
                <Box mt={2}>
                  <Button variant="outlined" onClick={() => handleEdit(accessory)}>
                    Edit
                  </Button>
                </Box>
                <Box mt={2}>
                  <Button
                    variant="outlined"
                    onClick={() => handleDelete(accessory.id)}
                  >
                    Delete
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
        <Grid item xs={12} sm={6} md={4}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Tooltip title="Add new accessory">
              <IconButton
                onClick={() => {
                  setEditAccessory({
                    id: 0,
                    name: "",
                    description: "",
                    price: "",
                    imageData: "",
                  });
                  setDialogOpen(true);
                }}
              >
                <AddIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>{editAccessory?.id ? "Edit Accessory" : "Add Accessory"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            name="name"
            value={editAccessory?.name || ""}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            variant="outlined"
            name="description"
            value={editAccessory?.description || ""}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Price"
            type="text"
            fullWidth
            variant="outlined"
            name="price"
            value={editAccessory?.price || ""}
            onChange={handleChange}
          />
          <input
            type="file"
            onChange={handleImageChange}
            accept="image/*"
            style={{ display: "none" }}
            id="upload-image"
          />
          <label htmlFor="upload-image">
            <Button variant="contained" component="span">
              Upload Image
            </Button>
          </label>
          {selectedImage && <Typography>{selectedImage.name}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AdminAccessoriesPage;
