import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Container,
} from "@mui/material";
import axiosInstance from "../utils/axiosConfig";
import { Helmet } from "react-helmet";

interface Accessory {
  id: number;
  name: string;
  description: string;
  imageData: string;
  price: string;
}

const AccessoriesPage: React.FC = () => {
  const [accessories, setAccessories] = useState<Accessory[]>([]);

  const fetchData = () => {
    axiosInstance
      .get<Accessory[]>("accessories/public")
      .then((response) =>
        setAccessories(
          response.data.map((accessory) => ({
            ...accessory,
            imageData: `data:image/jpeg;base64,${accessory.imageData}`,
          }))
        )
      )
      .catch((error) => console.error("Failed to fetch accessories:", error));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://4okelari.ro/accesorii" />

        <title>Accesorii pentru ochelari - 4okelari</title>

        <meta
          name="description"
          content="Descoperă o gamă variată de accesorii pentru ochelari disponibile pe 4okelari. Găsește cele mai bune prețuri pentru accesorii de calitate."
        />

        <meta
          property="og:title"
          content="Accesorii pentru ochelari - 4okelari"
        />
        <meta
          property="og:description"
          content="Alege dintr-o varietate de accesorii pentru ochelari disponibile pe 4okelari. Accesorii de calitate la cele mai bune prețuri."
        />
        <meta property="og:url" content="https://4okelari.ro/accesorii" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://4okelari.ro/4okelari_logo.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Accesorii pentru ochelari - 4okelari"
        />
        <meta
          name="twitter:description"
          content="Alege dintr-o varietate de accesorii pentru ochelari disponibile pe 4okelari. Accesorii de calitate la cele mai bune prețuri."
        />
        <meta
          name="twitter:image"
          content="https://4okelari.ro/4okelari_logo.png"
        />

        <meta
          name="keywords"
          content="accesorii ochelari, 4okelari, prețuri accesorii, accesorii de calitate"
        />
        <meta name="author" content="4okelari" />
      </Helmet>
      <Container>
        <Typography
          variant="h4"
          sx={{ mt: 4, mb: 2, fontSize: { xs: "1.5rem", sm: "2rem" } }}
        >
          Accesorii
        </Typography>
        <Grid container spacing={4}>
          {accessories.map((accessory) => (
            <Grid item key={accessory.id} xs={6} sm={6} md={4} lg={3}>
              <Card>
                <CardMedia
                  component="img"
                  sx={{
                    height: { xs: 140, sm: 300 },
                    width: "100%",
                    objectFit: "cover",
                  }}
                  image={accessory.imageData}
                  alt={accessory.name}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
                  >
                    {accessory.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
                  >
                    {accessory.description}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
                  >
                    {accessory.price} lei
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default AccessoriesPage;
