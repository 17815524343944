import React, { useEffect, useState } from "react";
import axiosInstance from "../utils/axiosConfig"; // Clearly named custom Axios instance
import GenericTable from "../components/GenericTable";
import AdminActions from "../buttons/AdminActions";
import { toast } from "react-toastify";
import GenericForm from "../components/GenericForm";
import ConfirmDialog from "../components/ConfirmDialog";

interface Lens {
  id: number;
  name: string;
  description: string;
  price: string;
}

const columns = [
  { id: "name", label: "Lentile" },
  { id: "description", label: "Descriere" },
  { id: "price", label: "Pret" },
];

const formFields = [
  { id: "name", label: "Lentile", type: "text" },
  { id: "description", label: "Descriere", type: "text" },
  { id: "price", label: "Pret", type: "text" },
];

const LensPage: React.FC = () => {
  const [lens, setLens] = useState<Lens[]>([]);
  const [formOpen, setFormOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [currentLens, setCurrentLens] = useState<Lens | null>(null);

  const fetchData = () => {
    axiosInstance
      .get<Lens[]>("/lens/public")
      .then((response) => {
        setLens(response.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleAdd = () => {
    setCurrentLens(null);
    setFormOpen(true);
  };

  const handleEdit = (id: number) => {
    const len = lens.find((l) => l.id === id);
    if (len) {
      setCurrentLens(len);
      setFormOpen(true);
    } else {
      toast.error("Lens not found");
    }
  };

  const handleDelete = (id: number) => {
    setDeleteId(id);
    setDeleteConfirmOpen(true);
  };

  const closeForm = () => {
    setFormOpen(false);
  }

  const confirmDelete = () => {
    if (deleteId) {
      axiosInstance
        .delete(`/lens/${deleteId}`)
        .then(() => {
          fetchData();
          setDeleteId(null);
          setDeleteConfirmOpen(false);
          toast.success("Lens deleted successfully");
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  const saveLens = (data: { [key: string]: any } )=> {
    const method = currentLens ? "put" : "post";
    const url = currentLens ? `/lens/${currentLens.id}` : "/lens";

    axiosInstance[method](url, data)
      .then(() => {
        fetchData();
        setFormOpen(false);
        toast.success("Lens saved successfully");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <div>
      <h1>Lentile</h1>
      <AdminActions onAdd={handleAdd} />
      <GenericTable
        columns={columns}
        data={lens}
        editAction={handleEdit}
        deleteAction={handleDelete}
      />
      {formOpen && (
        <GenericForm
          open={formOpen}
          onClose={closeForm}
          fields={formFields}
          onSave={saveLens}
          initialData={currentLens ? currentLens : {}}
        />
      )}
      <ConfirmDialog
        open={deleteConfirmOpen}
        title="Confirm Delete"
        onClose={() => setDeleteConfirmOpen(false)}
        onConfirm={confirmDelete}
      >
        Are you sure you want to delete this lens?
      </ConfirmDialog>
    </div>
  );
};

export default LensPage;
