import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Typography,
  Container,
  Box,
} from "@mui/material";
import axiosInstance from "../utils/axiosConfig";
import { Helmet } from "react-helmet";

interface Feedback {
  experience: string;
  recommend: string;
  improvements: string[];
  comments: string;
}

const FeedbackPage: React.FC = () => {
  const [feedback, setFeedback] = useState<Feedback>({
    experience: "",
    recommend: "",
    improvements: [],
    comments: "",
  });

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFeedback({ ...feedback, [name]: value });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    let newList = [...feedback.improvements];
    if (checked && !newList.includes(name)) {
      newList.push(name);
    } else {
      newList = newList.filter((item) => item !== name);
    }
    setFeedback({ ...feedback, improvements: newList });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await axiosInstance.post("/feedback/public", feedback);
      toast.success("Feedback submitted successfully!");
      setFeedback({
        experience: "",
        recommend: "",
        improvements: [],
        comments: "",
      });
    } catch (error: any) {
      toast.error(
        "Error submitting feedback: " + error.response?.data?.message
      );
    }
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://4okelari.ro/feedback" />

        <title>Formular Feedback - 4okelari</title>

        <meta
          name="description"
          content="Oferă feedback despre experiența ta cu 4okelari. Ne-ar plăcea să aflăm ce părere ai despre produsele și serviciile noastre."
        />

        <meta property="og:title" content="Formular Feedback - 4okelari" />
        <meta
          property="og:description"
          content="Completează formularul de feedback și ajută-ne să îmbunătățim serviciile și produsele oferite de 4okelari."
        />
        <meta property="og:url" content="https://4okelari.ro/feedback" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://4okelari.ro/4okelari_logo.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Formular Feedback - 4okelari" />
        <meta
          name="twitter:description"
          content="Ajută-ne să îmbunătățim produsele și serviciile noastre completând formularul de feedback."
        />
        <meta
          name="twitter:image"
          content="https://4okelari.ro/4okelari_logo.png"
        />

        <meta
          name="keywords"
          content="feedback, 4okelari, formular feedback, recenzii, imbunatatiri produse"
        />
        <meta name="author" content="4okelari" />
      </Helmet>
      <Container component="main" maxWidth="sm">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h4" sx={{ mb: 4 }}>
            Formular de Feedback
          </Typography>
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <Box sx={{ mb: 2 }}>
              <FormControl fullWidth>
                <FormLabel>Experienta Generala</FormLabel>
                <Select
                  name="experience"
                  value={feedback.experience}
                  onChange={handleInputChange}
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="">
                    <em>Selectati</em>
                  </MenuItem>
                  <MenuItem value="excellent">Excelenta</MenuItem>
                  <MenuItem value="good">Buna</MenuItem>
                  <MenuItem value="average">Mediocra</MenuItem>
                  <MenuItem value="poor">Slaba</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ mb: 2 }}>
              <FormControl>
                <FormLabel>Ai recomanda magazinul si altora?</FormLabel>
                <RadioGroup
                  name="recommend"
                  value={feedback.recommend}
                  onChange={handleInputChange}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Da"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="Nu" />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box sx={{ mb: 2 }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  Ce am putea imbunatati? (Selectati toate variantele care se
                  potrivesc)
                </FormLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={feedback.improvements.includes("service")}
                      onChange={handleCheckboxChange}
                      name="service"
                    />
                  }
                  label="Serviciu Clienti"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={feedback.improvements.includes("quality")}
                      onChange={handleCheckboxChange}
                      name="quality"
                    />
                  }
                  label="Calitatea Produselor"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={feedback.improvements.includes("selection")}
                      onChange={handleCheckboxChange}
                      name="selection"
                    />
                  }
                  label="Selectia de Produse"
                />
              </FormControl>
            </Box>

            <Box sx={{ mb: 2 }}>
              <TextField
                name="comments"
                label="Alte comentarii sau sugestii"
                multiline
                rows={4}
                fullWidth
                value={feedback.comments}
                onChange={handleInputChange}
              />
            </Box>

            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </form>
        </Box>
      </Container>
    </>
  );
};

export default FeedbackPage;
