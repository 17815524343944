import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";

interface FormField {
  id: string;
  label: string;
  type: string;
  options?: { value: string | number; label: string }[];
}

interface GenericFormProps {
  open: boolean;
  onClose: () => void;
  fields: FormField[];
  onSave: (data: { [key: string]: any }) => void;
  initialData?: { [key: string]: any };
}

const GenericForm: React.FC<GenericFormProps> = ({
  open,
  onClose,
  fields,
  onSave,
  initialData = {},
}) => {
  const [formData, setFormData] = useState<{ [key: string]: any }>(
    () => initialData
  );

  const handleChange = (id: string, value: any) => {
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSave(formData);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Add New Item</DialogTitle>
        <DialogContent>
          {fields.map((field) => (
            <TextField
              key={field.id}
              margin="dense"
              label={field.label}
              type={field.type}
              fullWidth
              variant="outlined"
              value={formData[field.id] || ""}
              onChange={(e) => handleChange(field.id, e.target.value)}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Save</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default GenericForm;
