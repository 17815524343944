import { jwtDecode } from 'jwt-decode';

interface DecodedToken {
    roles: string[];
}

export const getUserRole = (): string[] | null => {
    const token = localStorage.getItem('authToken');
    if (!token) return null;
    try {
        const decoded: DecodedToken = jwtDecode(token);
        return decoded.roles;
    } catch (error) {
        console.error('Failed to decode token:', error);
        return null;
    }
};
