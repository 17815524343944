import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Paper, TablePagination, TextField, Box, IconButton
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getUserRole } from '../utils/roleUtils'; // Import the role checking utility

interface Column {
  id: string;
  label: string;
  format?: (value: any) => string;
}

interface Data {
  id: number; 
  [key: string]: any;
}

interface GenericTableProps {
  columns: Column[];
  data: Data[];
  rowsPerPageInitial?: number;
  editAction?: (id: number) => void;
  deleteAction?: (id: number) => void;
}

const GenericTable: React.FC<GenericTableProps> = ({
  columns, data, rowsPerPageInitial = 5, editAction, deleteAction
}) => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(rowsPerPageInitial);
  const [page, setPage] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredData, setFilteredData] = useState<Data[]>([]);
  const roles = getUserRole();  // Get user roles from utility

  useEffect(() => {
    setFilteredData(data.filter(item =>
      columns.some(column =>
        item[column.id]?.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    ));
  }, [searchTerm, data, columns]);

  const isAdmin = roles?.includes('ROLE_ADMIN');  // Check if the user is an admin

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Box sx={{ padding: 2 }}>
        <TextField
          fullWidth
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
      </Box>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
              {isAdmin && <TableCell>Actiuni</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow hover key={index}>
                {columns.map(column => (
                  <TableCell key={column.id}>{column.format ? column.format(row[column.id]) : row[column.id]}</TableCell>
                ))}
                {isAdmin && (
                  <TableCell>
                    {editAction && (
                      <IconButton onClick={() => editAction(row.id)} color="primary">
                        <EditIcon />
                      </IconButton>
                    )}
                    {deleteAction && (
                      <IconButton onClick={() => deleteAction(row.id)} color="secondary">
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => setPage(newPage)}
        onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
      />
    </Paper>
  );
};

export default GenericTable;
