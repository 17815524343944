import React, { useState, useEffect, useCallback } from "react";
import Carousel from "react-material-ui-carousel";
import { Frame } from "../pages/GlassesPage";
import axiosInstance from "../utils/axiosConfig";
import { Box, Typography, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface CarouselProps {
  apiEndpoint: string; // API endpoint to fetch the frames
}

const FramesCarousel: React.FC<CarouselProps> = ({ apiEndpoint }) => {
  const [products, setProducts] = useState<Frame[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate(); // Hook for navigation

  // Fetch data with proper formatting for imageData
  const fetchData = useCallback(() => {
    axiosInstance
      .get<Frame[]>(apiEndpoint)
      .then((response) => {
        setProducts(
          response.data.slice(0,3).map((frame) => ({
            ...frame,
            imageData: `data:image/jpeg;base64,${frame.imageData}`, // Format imageData as base64
          }))
        );
        setLoading(false);
      })
      .catch((error) => {
        setError("Failed to load frames");
        setLoading(false);
        console.error("Failed to fetch frames:", error);
      });
  }, [apiEndpoint]);

  // Fetch frames on component mount or when apiEndpoint changes
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Loading and error handling
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "300px",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography
        variant="h6"
        color="error"
        sx={{ textAlign: "center", mt: 4 }}
      >
        {error}
      </Typography>
    );
  }

  // Handler to navigate to the product details page
  const handleImageClick = (id: number) => {
    navigate(`/rame/${id}`); // Navigates to the details page of the clicked frame
  };

  return (
    <Carousel navButtonsAlwaysVisible={false}>
      {products.map((product) => (
        <Box
          key={product.id}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: 2,
          }}
        >
          <img
            src={product.imageData}
            alt={product.name}
            style={{ width: "100%", height: "auto", maxWidth: "600px", cursor: "pointer" }}
            onClick={() => handleImageClick(product.id)} // Redirects on click
          />
          <Typography variant="h6" sx={{ mt: 2 }}>
            {product.name}
          </Typography>
        </Box>
      ))}
    </Carousel>
  );
};

export default FramesCarousel;
