import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#111810', // Teal Blue as the primary color
      contrastText: '#F5F5F5', // Cream White for text on primary color
    },
    secondary: {
      main: '#F88379', // Soft Coral as the secondary color
      contrastText: '#36454F', // Charcoal Gray for text on secondary color
    },
    error: {
      main: '#E57373', // Soft red for errors
    },
    background: {
      default: '#F5F5F5', // Cream White for general backgrounds
      paper: '#ffffff', // Pure white for areas like cards and sheets
    },
    text: {
      primary: '#36454F', // Charcoal Gray for primary text
      secondary: '#2F4F4F', // Dark Slate Gray for secondary text
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif', // Default font for body text
    h1: {
      fontFamily: '"Montserrat", "Roboto", sans-serif', // Montserrat for headers
      fontWeight: 500, // Medium font weight for h1
      color: '#008080', // Use Teal Blue for headers
    },
    body1: {
      fontWeight: "bold",
      // fontSize: "14px",
    },
    button: {
      textTransform: 'none', // Buttons with normal case text
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8, // Rounded corners for buttons
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow for cards
          borderRadius: 12, // Smoother rounded corners for cards
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#36454F', // Charcoal Gray for the app bar
        }
      }
    }
  },
});

export default theme;
