import React from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import theme from "../theme"; // Import your custom theme
import CallButton from "../buttons/CallButton";
import FramesCarousel from "../components/FramesCarousel";
import { Helmet } from "react-helmet";

const HomePage: React.FC = () => {
  const phoneNumber = "0770-105-376";

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://4okelari.ro/" />

        <title>4okelari - Magazinul tău de ochelari</title>

        <meta
          name="description"
          content="4okelari oferă ochelari de calitate pentru femei și bărbați. Alege dintr-o gamă variată de rame și lentile la prețuri accesibile."
        />

        <meta
          property="og:title"
          content="4okelari - Ochelari de calitate pentru toată familia"
        />
        <meta
          property="og:description"
          content="Descoperă ochelari pentru femei și bărbați pe 4okelari.ro. Calitate garantată la prețuri accesibile!"
        />
        <meta property="og:url" content="https://4okelari.ro/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://4okelari.ro/path-to-your-social-image.jpg"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="4okelari - Ochelari de calitate pentru toată familia"
        />
        <meta
          name="twitter:description"
          content="Descoperă ochelari pentru femei și bărbați pe 4okelari.ro. Calitate garantată la prețuri accesibile!"
        />
        <meta
          name="twitter:image"
          content="https://4okelari.ro/path-to-your-social-image.jpg"
        />

        <meta
          name="keywords"
          content="ochelari, rame, lentile, femei, barbati, online, București"
        />
        <meta name="author" content="4okelari" />
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          overflow: "hidden",
        }}
      >
        <Container
          sx={{
            flex: 1,
            mt: 4,
            mb: 6,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Typography
            variant={isMobile ? "h5" : "h4"} // Adjust variant for mobile
            sx={{ fontWeight: "bold", mt: 2, mb: 3 }}
          >
            Magazin de ochelari online sau chiar în casa ta
          </Typography>
          <Grid container justifyContent="center">
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                backgroundColor: "white",
              }}
            >
              <Stack
                sx={{ alignItems: "center", maxWidth: "450px", mx: "auto" }}
                spacing={2}
              >
                <Typography
                  variant={isMobile ? "h6" : "h5"} // Adjust variant for mobile
                  sx={{ fontWeight: "bold", fontSize: "18px", padding: "35px" }}
                  margin="2"
                  justifyContent="center"
                  padding="128"
                >
                  Rame de ochelari pentru femei și bărbați cu prețuri
                  competitive.
                </Typography>
                <Stack
                  direction="row"
                  sx={{ alignItems: "center", justifyContent: "center" }}
                  spacing={2}
                >
                  <Button
                    component={Link}
                    to="/ramebarbati"
                    variant="contained"
                    sx={{
                      borderRadius: "20px",
                      backgroundColor: "#111810",
                      color: "#fff",
                      "&:hover": { backgroundColor: "#333940" },
                    }}
                  >
                    Bărbați
                  </Button>
                  <Button
                    component={Link}
                    to="/ramefemei"
                    variant="contained"
                    sx={{
                      borderRadius: "20px",
                      backgroundColor: "#111810",
                      color: "#fff",
                      "&:hover": { backgroundColor: "#333940" },
                    }}
                  >
                    Femei
                  </Button>
                </Stack>
                <Box
                  component="img"
                  src="rama_home.jpeg"
                  alt="Description of the image"
                  sx={{ width: "100%", height: "auto" }}
                />
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#ffffff",
                }}
              >
                <FramesCarousel apiEndpoint="frames/public" />
              </Box>
            </Grid>
          </Grid>
          <Typography variant={isMobile ? "h6" : "h5"} sx={{ mt: 2 }}>
            Tel. <strong>{phoneNumber}</strong>
          </Typography>
          <CallButton isHomePage />
          <Box sx={{ mt: 6 }}>
            <Typography variant={isMobile ? "h5" : "h4"} gutterBottom>
              Avantaje
            </Typography>
            <List sx={{ mx: "auto", maxWidth: 1100 }}>
              <ListItem>
                <ListItemText
                  primaryTypographyProps={{
                    variant: isMobile ? "body2" : "body1",
                  }}
                  primary="Prețuri super competitive"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primaryTypographyProps={{
                    variant: isMobile ? "body2" : "body1",
                  }}
                  primary="Gamă variată de lentile, inclusiv lentile Essilor"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primaryTypographyProps={{
                    variant: isMobile ? "body2" : "body1",
                  }}
                  primary="În București putem veni la dvs. direct la birou/domiciliu, pentru a vă prezenta colecția noastră de rame și de lentile și pentru a vă prelua comanda. Când sunt gata, ochelarii sunt livrați tot la birou/domiciliu. Livrarea este gratuită."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primaryTypographyProps={{
                    variant: isMobile ? "body2" : "body1",
                  }}
                  primary="Modele superbe de rame - Săptămânal includem în ofertă modele noi"
                />
              </ListItem>
            </List>
          </Box>

          <Divider sx={{ my: 6 }} />
        </Container>

        <Box
          sx={{
            textAlign: "center",
            py: 2,
            borderTop: "1px solid #ddd",
            backgroundColor: "#f9f9f9",
            mt: "auto",
            position: "relative",
          }}
        >
          <Typography variant="body2" color="text.secondary">
            &copy; {new Date().getFullYear()} Adina Grecu. All rights reserved.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default HomePage;
