import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utils/axiosConfig";
import { Helmet } from "react-helmet";

export interface Frame {
  id: number;
  name: string;
  description: string;
  imageData: string;
  price: string;
}

interface GlassesPageProps {
  gender: string;
}

const GlassesPage: React.FC<GlassesPageProps> = ({ gender }) => {
  const [frames, setFrames] = useState<Frame[]>([]);
  const navigate = useNavigate();

  const fetchData = useCallback(() => {
    axiosInstance
      .get<Frame[]>(`frames/public/gender/${gender}`)
      .then((response) =>
        setFrames(
          response.data.map((frame) => ({
            ...frame,
            imageData: `data:image/jpeg;base64,${frame.imageData}`,
          }))
        )
      )
      .catch((error) => console.error("Failed to fetch frames:", error));
  }, [gender]);

  useEffect(() => {
    fetchData();
  }, [gender, fetchData]);

  const handleCardClick = (id: number) => {
    navigate(`/rame/${id}`);
  };

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={
            gender === "female"
              ? "https://4okelari.ro/ramefemei"
              : "https://4okelari.ro/ramebarbati"
          }
        />

        <title>
          {gender === "female"
            ? "Rame Femei - 4okelari"
            : "Rame Bărbați - 4okelari"}
        </title>

        <meta
          name="description"
          content={
            gender === "female"
              ? "Descoperă o gamă variată de rame pentru femei pe 4okelari. Cumpără rame de ochelari de calitate la cele mai bune prețuri."
              : "Alege dintr-o selecție diversificată de rame pentru bărbați pe 4okelari. Găsește rame elegante și confortabile la prețuri accesibile."
          }
        />

        <meta
          property="og:title"
          content={
            gender === "female"
              ? "Rame Femei - 4okelari"
              : "Rame Bărbați - 4okelari"
          }
        />
        <meta
          property="og:description"
          content={
            gender === "female"
              ? "Cumpără rame pentru femei de la 4okelari. Calitate garantată la cele mai bune prețuri."
              : "Descoperă rame pentru bărbați pe 4okelari. Oferim rame confortabile și elegante la prețuri excelente."
          }
        />
        <meta
          property="og:url"
          content={
            gender === "female"
              ? "https://4okelari.ro/ramefemei"
              : "https://4okelari.ro/ramebarbati"
          }
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://4okelari.ro/4okelari_logo.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={
            gender === "female"
              ? "Rame Femei - 4okelari"
              : "Rame Bărbați - 4okelari"
          }
        />
        <meta
          name="twitter:description"
          content={
            gender === "female"
              ? "Găsește rame pentru femei de calitate la prețuri accesibile pe 4okelari."
              : "Cumpără rame pentru bărbați, elegante și confortabile, pe 4okelari."
          }
        />
        <meta
          name="twitter:image"
          content="https://4okelari.ro/4okelari_logo.png"
        />

        <meta
          name="keywords"
          content={
            gender === "female"
              ? "rame femei, ochelari femei, rame de ochelari, ochelari ieftini, rame de calitate"
              : "rame bărbați, ochelari bărbați, rame de ochelari, rame elegante, ochelari de calitate"
          }
        />
        <meta name="author" content="4okelari" />
      </Helmet>

      <Container>
        <Typography
          variant="h4"
          sx={{ mt: 4, mb: 2, fontSize: { xs: "1.5rem", sm: "2rem" } }}
        >
          {gender === "female" ? "Rame pentru Femei" : "Rame pentru Barbati"}
        </Typography>
        <Grid container spacing={4}>
          {frames.map((frame) => (
            <Grid item key={frame.id} xs={12} sm={6} md={4}>
              <Card
                onClick={() => handleCardClick(frame.id)}
                sx={{ cursor: "pointer" }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    height: { xs: 140, sm: 300 },
                    width: "100%",
                    objectFit: "cover",
                  }}
                  image={frame.imageData}
                  alt={frame.name}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {frame.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {frame.description}
                  </Typography>
                  <Typography variant="h6">{frame.price} lei</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default GlassesPage;
