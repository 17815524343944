import React from 'react';
import { Box } from '@mui/material';

const GradientDivider: React.FC = () => {
  return (
    <Box
      sx={{
        height: '1px',
        background: 'linear-gradient(to right, rgba(0, 0, 0, 0.12) 0%, rgba(255, 255, 255, 0) 100%)',
        width: '100%',
      }}
    />
  );
};

export default GradientDivider;
