import React from "react";
import { Container, Typography, Grid, Box, Link, Paper } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Helmet } from "react-helmet";

const ContactPage = () => {
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://4okelari.ro/contact" />

        <title>Contact - Gheorghiu Florin Pompiliu | 4okelari</title>

        <meta
          name="description"
          content="Contactați-l pe Gheorghiu Florin Pompiliu, specialist optometrist, activitate în București și online. Pentru detalii: 0770-105-376."
        />

        <meta
          property="og:title"
          content="Contact - Gheorghiu Florin Pompiliu | 4okelari"
        />
        <meta
          property="og:description"
          content="Activitatea se desfășoară în București și online. Contactați-l pe Gheorghiu Florin Pompiliu la 0770-105-376 pentru detalii."
        />
        <meta property="og:url" content="https://4okelari.ro/contact" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://4okelari.ro/4okelari_logo.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Contact - Gheorghiu Florin Pompiliu | 4okelari"
        />
        <meta
          name="twitter:description"
          content="Activitatea are loc în București și online. Contactați-l pe Gheorghiu Florin Pompiliu la 0770-105-376."
        />
        <meta
          name="twitter:image"
          content="https://4okelari.ro/4okelari_logo.png"
        />

        <meta
          name="keywords"
          content="contact, Gheorghiu Florin Pompiliu, optometrist, București, online, 4okelari, telefon, email, locație"
        />
        <meta name="author" content="4okelari" />
      </Helmet>
      <Container sx={{ marginTop: 5, padding: 0 }}>
        <Paper
          elevation={2}
          sx={{
            padding: 4,
            marginBottom: 4,
            maxWidth: "100%",
            width: "100%",
            margin: "auto",
            boxSizing: "border-box",
          }}
        >
          <Typography variant="h4" gutterBottom align="center">
            Despre mine
          </Typography>
          <Typography variant="body1" align="center">
            Gheorghiu Florin Pompiliu - specialist optometrist cu o experienșță
            de peste 15 ani în domeniu. Am pornit de la a vinde rame și lentile
            în Târgul Vitan și am ajuns la a vinde astfel de produse, fizic, în
            București și online, în întreaga țara. Mă recomandă prețurile mici
            și calitatea impecabilă a ochelarilor. Pentru mai multe detalii, nu
            ezitați să mă contactați.
          </Typography>
        </Paper>

        <Paper
          elevation={2}
          sx={{
            padding: 4,
            marginTop: 5,
            maxWidth: "100%",
            width: "100%",
            margin: "auto",
            boxSizing: "border-box",
          }}
        >
          <Typography variant="h4" gutterBottom align="center">
            Contactați-mă
          </Typography>

          <Box sx={{ marginY: 4, textAlign: "center" }}>
            <Grid container spacing={4} justifyContent="center">
              <Grid item xs={12} sm={4}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <PhoneIcon
                    sx={{
                      fontSize: 40,
                      color: "primary.main",
                      marginBottom: 1,
                    }}
                  />
                  <Typography variant="h6">0770-105-376</Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <EmailIcon
                    sx={{
                      fontSize: 40,
                      color: "primary.main",
                      marginBottom: 1,
                    }}
                  />
                  <Link
                    href="mailto:ochelarideprotectie453@gmail.com"
                    underline="none"
                  >
                    <Typography
                      variant="h6"
                      sx={{ wordWrap: "break-word", textAlign: "center" }}
                    >
                      ochelarideprotectie453@gmail.com
                    </Typography>
                  </Link>
                </Box>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <LocationOnIcon
                    sx={{
                      fontSize: 40,
                      color: "primary.main",
                      marginBottom: 1,
                    }}
                  />
                  <Typography variant="h6" align="center">
                    Săbăreni, Str. Ghioceilor, nr. 9, Sat Săbăreni, jud. Giurgiu
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ marginTop: 4, textAlign: "center" }}>
            <Typography variant="h5" gutterBottom align="center">
              Date Fiscale
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Furnizor:</strong> Gheorghe Pompiliu-Florin
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 1 }}>
                  <strong>Persoana Fizica Autorizata</strong>
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Autorizatie:</strong> F52/86/2015
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>CIF:</strong> 34264848
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Sediu:</strong> Săbăreni, Str. Ghioceilor, nr. 9, Sat
                  Săbăreni, jud. Giurgiu
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default ContactPage;
