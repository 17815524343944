import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Typography, Grid, Box } from "@mui/material";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import axiosInstance from "../utils/axiosConfig";
import CallButton from "../buttons/CallButton";
import { Helmet } from "react-helmet";

interface Frame {
  id: number;
  name: string;
  description: string;
  imageData: string;
  price: string;
  color: string;
}

const GlassDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [frame, setFrame] = useState<Frame | null>(null);

  useEffect(() => {
    axiosInstance
      .get<Frame>(`frames/public/${id}`)
      .then((response) =>
        setFrame({
          ...response.data,
          imageData: `data:image/jpeg;base64,${response.data.imageData}`,
        })
      )
      .catch((error) => console.error("Failed to fetch frame details:", error));
  }, [id]);

  if (!frame) {
    return (
      <Typography variant="h5" sx={{ mt: 4 }}>
        Loading...
      </Typography>
    );
  }

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`https://4okelari.ro/rame/${frame.id}`} />

        <title>{`${frame.name} - Ochelari de calitate | 4okelari`}</title>

        <meta
          name="description"
          content={`Descoperă ochelari ${frame.name} de culoare ${frame.color}. ${frame.description}. Cumpără acum la prețul de ${frame.price}.`}
        />

        <meta
          property="og:title"
          content={`${frame.name} - Ochelari de calitate | 4okelari`}
        />
        <meta property="og:description" content={frame.description} />
        <meta
          property="og:url"
          content={`https://4okelari.ro/rame/${frame.id}`}
        />
        <meta property="og:type" content="product" />
        <meta property="og:image" content={frame.imageData} />
        <meta property="og:price:amount" content={frame.price} />
        <meta property="og:price:currency" content="RON" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={`${frame.name} - Ochelari de calitate | 4okelari`}
        />
        <meta name="twitter:description" content={frame.description} />
        <meta name="twitter:image" content={frame.imageData} />

        <meta
          name="keywords"
          content={`ochelari, ${frame.name}, rame, ${frame.color}, lentile, ochelari de calitate`}
        />
        <meta name="author" content="4okelari" />
      </Helmet>
      <Box sx={{ mt: 4, padding: 0 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Zoom>
              <img
                src={frame.imageData}
                alt={frame.name}
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              />
            </Zoom>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h5" fontWeight="bold" gutterBottom>
              {frame.name}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" gutterBottom>
              {frame.color}
            </Typography>
            <Typography
              variant="h5"
              fontWeight="bold"
              gutterBottom
              sx={{ mt: 2 }}
            >
              {frame.price} lei
            </Typography>
            <CallButton />
            <Typography variant="h6" color="text.secondary" sx={{ mt: 4 }}>
              {frame.description}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default GlassDetailsPage;
